import React, {Fragment, useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import FixedRatioImage from '../../components/FixedRatioImage';
import {
  Button as AntButton,
  Form,
  Input,
  Select,
  Upload,
  Result,
  DatePicker,
} from 'antd';
import Button from '../../components/Button';
import {Context} from '../../AppContext';
import {UploadOutlined} from '@ant-design/icons';
import {navigate} from 'gatsby-link';
import URL_PATH from '../../UrlPath';
import {
  BREAK_POINTS,
  FORETASTE_STATUS,
  FORETASTE_TYPE,
  FORETASTE_TYPE_DISPLAY,
} from '../../domain/Constants';
import imgForetasteBanner from '../../images/foretaste-banner-20230223.jpeg';
import imgForetasteMenuBirth from '../../images/foretaste-menu-birth.jpeg';
import imgForetasteMenuWedding from '../../images/foretaste-menu-wedding.jpeg';
import QRCode from '../../images/leruban_line@.png';

const {TextArea} = Input;
const {Option} = Select;

const ForetastePage = (props) => {
  const app = useContext(Context);
  const {
    pageContext: {homeData},
  } = props;
  const [type, setType] = useState({type: FORETASTE_TYPE.brideCake});
  const [isSendFormSuccess, setIsSendFormSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [image, setImage] = useState({
    uid: '',
    url: '',
    fields: null,
    filename: '',
    expected: '',
  });

  const onFinish = async (values) => {
    setLoading(true);

    await app.actions.createForetasteOrder({
      ...values,
      date: values.date.format('YYYY-MM-DD'),
      status: FORETASTE_STATUS.pending,
      imageUrl: `${image.expected}`,
    });
    setLoading(false);
    setIsSendFormSuccess(true);
  };

  const onFinishFailed = (error) => {
    console.log(error);
  };

  const beforeUpload = async (file) => {
    try {
      const token = await app.actions.getUploadToken();
      const resp = await app.actions.storagePresigned({
        file,
        token: token.token,
      });
      setImage((prev) => ({...prev, ...resp}));
    } catch (e) {
      console.log(e);
    }
  };

  const onFileChange = (props) => {
    const {file} = props;
    if (file.status === 'done') {
      setImage({
        ...image,
        uid: file.uid,
        filename: file.name,
      });
    }
  };

  const isFormAllowSubmit = React.useMemo(
    () => homeData && homeData.allow_foretaste_order,
    [homeData],
  );

  return (
    <Container>
      <FixedRatioImage
        width="100%"
        mode="cover"
        image={imgForetasteBanner}
        ratio={550 / 1440}
      />
      <div className="inner" style={{width: '70%', margin: '0 auto'}}>
        {!isFormAllowSubmit && homeData?.foretaste_order_closed_hint && (
          <div className="form-closed-hint">
            {homeData.foretaste_order_closed_hint}
          </div>
        )}
        <section className="info">
          <div className="info-title">喜餅與彌月</div>
          <div className="info-description">
            法朋重視的是能夠一同分享的溫暖感受，想呈現給客人的是每個季節、每個時令，
            <br />
            結合自己生活土地上的食材所做成的甜點，結合四個不同節氣作為變化，
            <br />
            並堅持使用當季新鮮的材料，創造一個能夠滿足現代人們對於甜品的需求與想像的甜點城堡。
          </div>
        </section>
        <div className="menus">
          <img
            src={imgForetasteMenuWedding}
            onClick={() =>
              homeData?.foretaste_wedding_img &&
              window.open(homeData.foretaste_wedding_img, '_blank')
            }
          />
          <img
            src={imgForetasteMenuBirth}
            onClick={() =>
              homeData?.foretaste_birth_img &&
              window.open(homeData.foretaste_birth_img, '_blank')
            }
          />
        </div>

        {isSendFormSuccess && (
          <Result
            title="我們已收到您的表單！"
            subTitle="客服人員將與您聯繫"
            status="success"
            extra={[
              <AntButton
                type="primary"
                onClick={() => {
                  navigate(URL_PATH.landing);
                }}>
                返回首頁
              </AntButton>,
            ]}
          />
        )}

        {!isSendFormSuccess && (
          <Form
            className="form"
            initialValues={{
              type: FORETASTE_TYPE.brideCake,
              name: '',
              phone: '',
              email: '',
              gender: '',
              imageUrl: '',
              date: '',
              note: '',
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}>
            <header>
              <div className="title">問卷表單</div>
              <div className="description">
                對於我們有任何問題與報價需求，歡迎填寫表單或加入 LINE@
                客服與我們聯絡！
                <br />
                每月試吃時間會有一個場次，將由客服專人與您聯繫時間
                <br />
                建議您掃描 QRCode，加入 LINE@ 客服聯繫喔！
              </div>
              <img
                src={QRCode}
                alt="line@"
                width={100}
                height={100}
                style={{marginTop: 10}}
              />
            </header>

            <div className="input-group">
              <Form.Item
                name="type"
                className="form-item"
                rules={[{required: true, message: '必填'}]}>
                <Select
                  disabled={!isFormAllowSubmit}
                  placeholder="試吃類別"
                  onChange={(value) => {
                    setType({...type, type: value});
                  }}>
                  <Option value={FORETASTE_TYPE.brideCake}>
                    {FORETASTE_TYPE_DISPLAY.brideCake}
                  </Option>
                  <Option value={FORETASTE_TYPE.fullMonthCake}>
                    {FORETASTE_TYPE_DISPLAY.fullMonthCake}
                  </Option>
                </Select>
              </Form.Item>
              <Form.Item
                className="form-item"
                name="date"
                rules={[
                  {
                    required: true,
                    message: '必填',
                  },
                ]}>
                <DatePicker
                  style={{width: '100%'}}
                  disabled={!isFormAllowSubmit}
                  placeholder={
                    type.type === FORETASTE_TYPE.brideCake
                      ? '婚禮時間'
                      : '預產期時間'
                  }
                />
              </Form.Item>
            </div>

            <div className="input-group">
              <Form.Item
                className="form-item"
                name="name"
                rules={[
                  {
                    required: true,
                    message: '必填',
                  },
                ]}>
                <Input
                  disabled={!isFormAllowSubmit}
                  placeholder="請填寫您的大名"
                />
              </Form.Item>
              <Form.Item
                className="form-item"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: '必填',
                  },
                  {
                    pattern: /((?=(09))[0-9]{10})$/g,
                    message: '請確認電話號碼格式是否正確',
                  },
                ]}>
                <Input
                  disabled={!isFormAllowSubmit}
                  placeholder="請填寫您的手機號碼"
                  maxLength={10}
                />
              </Form.Item>
            </div>

            <div className="input-group">
              <Form.Item className="form-item" name="gender">
                <Select
                  placeholder="請填寫您的性別"
                  allowClear
                  disabled={!isFormAllowSubmit}>
                  <Option disabled value="">
                    請填寫您的性別
                  </Option>
                  <Option value="male">男</Option>
                  <Option value="female">女</Option>
                </Select>
              </Form.Item>

              <Form.Item
                className="form-item"
                name="email"
                rules={[
                  {
                    required: true,
                    message: '必填',
                  },
                  {
                    type: 'email',
                    message: '電子郵件不合法',
                  },
                ]}>
                <Input
                  disabled={!isFormAllowSubmit}
                  placeholder="請填寫您的電子郵件"
                />
              </Form.Item>
            </div>

            <div className="content">
              {type.type === FORETASTE_TYPE.brideCake && (
                <Fragment>
                  <div>請提供您任何有關婚禮相關文件</div>
                  <div>
                    舉凡預訂宴客會場相關證明、婚紗預訂相關證明或婚禮攝影相關證明，擇一翻拍上傳即可。
                  </div>
                </Fragment>
              )}
              {type.type === FORETASTE_TYPE.fullMonthCake && (
                <Fragment>
                  <div>請提供您任何有關懷孕相關文件</div>
                  <div>舉凡媽媽手冊或寶寶手冊,擇一翻拍封面上傳即可。</div>
                </Fragment>
              )}

              <Form.Item
                name="imageUrl"
                valuePropName="imageUrl"
                rules={[
                  {
                    required: true,
                    message: '必填',
                  },
                ]}>
                <Upload
                  maxCount={1}
                  onChange={onFileChange}
                  beforeUpload={beforeUpload}
                  defaultFileList={[]}
                  accept="image/*"
                  showUploadList={{
                    showPreviewIcon: true,
                    showRemoveIcon: true,
                  }}
                  onPreview={() => {
                    window.open(`${image.expected}`);
                  }}
                  action={image.url}
                  data={image.fields}>
                  <AntButton
                    disabled={!isFormAllowSubmit}
                    style={{margin: '10px 0'}}
                    icon={<UploadOutlined />}>
                    上傳
                  </AntButton>
                </Upload>
              </Form.Item>
            </div>

            <div className="content">
              <Form.Item style={{margin: 0}} name="note">
                <TextArea
                  disabled={!isFormAllowSubmit}
                  placeholder="請在此處填入您的 LINE id 以及其他問題留言喔"
                />
              </Form.Item>
            </div>
            <Button
              disabled={!isFormAllowSubmit}
              type="submit"
              size="small"
              loading={loading}>
              確認送出
            </Button>
          </Form>
        )}
      </div>
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: var(--content-max-width);
  margin: 0 auto;
  & > .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 69px;

    & > .menus {
      display: flex;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 20px;
      & > img {
        width: calc(50% - 10px);
        cursor: pointer;
      }
      & > img:first-child {
        margin-right: 10px;
      }
    }

    & > .form-closed-hint {
      padding: 20px;
      border: 1px solid var(--color-red);
      background-color: var(--color-red-2);
      border-radius: 2px;
      font-size: 14px;
      width: 100%;
      text-align: center;
      white-space: pre-wrap;
      margin-bottom: 40px;
    }

    & > .info {
      text-align: center;
      color: #595757;
      font-family: 'Noto Serif TC', serif;
      & > .info-title {
        font-size: 24px;
        margin-bottom: 26px;
      }
      & > .info-description {
        font-size: 14px;
        line-height: 30px;
      }
    }
    & > .form {
      display: flex;
      flex-direction: column;
      align-self: stretch;
      margin-bottom: 50px;
      & > header {
        text-align: center;
        color: #595757;
        font-family: 'Noto Serif TC', serif;
        margin-bottom: 50px;
        & > .title {
          font-size: 24px;
          margin-bottom: 20px;
        }
        & > .description {
          font-size: 14px;
          line-height: 30px;
        }
      }
      & > .input-group {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        & > .form-item {
          width: 48%;
        }
      }
      & > .content {
        display: flex;
        flex-direction: column;
        padding: var(--content-padding);
        border-radius: 5px;
        border: 1px solid #e5e5ea;
        background-color: #fff;
        margin-bottom: 20px;
      }
      & > button.small {
        align-self: center;
      }
    }
  }
  @media screen and (max-width: ${BREAK_POINTS.tablet}px) {
    & > .inner {
      & > .menus {
        flex-direction: column;
        & > img {
          width: 100%;
        }
        & > img:first-child {
          margin-right: 0px;
          margin-bottom: 10px;
        }
      }
      & > .info {
        & > .info-title {
        }
        & > .info-description {
        }
      }
      & > .form {
        & > header {
          & > .title {
          }
          & > .description {
          }
        }
        & > .input-group {
          flex-direction: column;
          margin-bottom: 0;
          & > .form-item {
            width: 100%;
            margin-bottom: 20px;
          }
        }
        & > .content {
        }
        & > button.small {
        }
      }
    }
  }
`;

export default ForetastePage;
